h1, h2, h3, h4, h5 {
  font-family: 'Futura PT Medium';
  font-weight: 450;
}
h2 {
  font-size: 28px;
  @media (min-width: 992px) {
    font-size: 35px;
    font-size: 1.8vw;
  }
  font-weight: 450;
  line-height: 45px;
}
h3 {
  font-size: 25px;
  line-height: 49px;
  margin-bottom: 10px;
}
h4 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 10px;
}
h5 {
  font-size: 20px;
  line-height: 49px;
  margin-bottom: 0;
}

.text-bold,
b {
  font-family: 'Futura PT Medium';
  font-weight: 450;
}
.text-small {
  font-size: 14px;
  line-height: 18px;
}

a {
  color: $linksAndBtns;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: mix(black, $linksAndBtns, 20);
  }
  &.link-blue {
    color: $linksAlt;
    &:hover {
      text-decoration: none;
      color: mix(black, $linksAlt, 20);
    }
  }
  &.noWrap {
    white-space: nowrap;
  }
}
.link-blue-wrapper {
   a {
     color: $linksAlt;
     &:hover {
       text-decoration: none;
       color: mix(black, $linksAlt, 20);
     }
  }
}
