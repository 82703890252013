@import '_variables.scss';
@import "~bootstrap/scss/bootstrap";
@import '@splidejs/splide/css';
@import '_font.scss';

@import "components/typography";
@import "components/form";
@import "components/btn";
@import "components/nav";
@import "components/footer";
@import "components/sidebar";
@import "components/block";
@import "components/card";
@import "components/article";
@import "components/slider";

html, body {
  height: 100vh;
}
body {
  background-color: $bodyBg;
  color: #2c302f;
  font-weight: 400;
  font-family: 'Futura PT Book',Trebuchet MS, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}
main {
  // height: 100vh;
  // height: -webkit-fill-available;
  // max-height: 100vh;
}

.container-fluid {
  padding-left: 2%;
  padding-right: 2%;
}
.page-home {
  min-height: 100%;
}

.main-content {
  padding: 20px 10px;
  @media (min-width: 991px) {
      padding: 30px;
  }
}

.gap-30 {
  gap: 15px;
  @media (min-width: 991px) {
      gap: 30px;
  }
}
.bg-blue {
  background-color: $themeBlue;
  color: $textOnBlue;
  .text-white {
    color: $textOnBlue;
  }
  .border-onBlue-bottom {
    border-color: $lineOnBlue!important;
  }
}
.bg-darkBlue {
  background-color: $themeBlueDark;
  color: $textOnBlue;
}
.bg-transparent {
  background-color: transparent;
}

.banner {
  border-radius: 10px;
  padding: 6.5%;
  color: $darkTextOnBlue;
  font-family: 'Futura PT Medium';
  font-weight: 450;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $themeBlue url('../images/banner.png') bottom right no-repeat;
  background-size: contain;
  &-body {
    flex:1;
    margin-top: 10%;
    text-align: center;
    margin-bottom: 150px;
  }
  &-text-big {
    color: $bodyBg;
    font-size: 85px;
    line-height: 109px;
    @media (min-width: 991px) {
      font-size: 4.4vw;
      line-height: 5.6vw;
    }
  }
  &-text {
    font-size: 22px;
    line-height: 30px;
  }
  &-footer {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    .btn {
      font-size: 19px;
      line-height: 24px;
    }
  }
  &-company {
    background: none;
    position: relative;
    min-height: 452px;
    padding: 10%;
    .banner-background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.5;
      overflow: hidden;
      z-index: 1;
      border-radius: 10px;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateY(-50%) translateX(-50%);
        height: 100%;
        width: auto;
        object-fit: cover;
      }
    }
    .banner-body {
      position: relative;
      z-index: 2;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.7);
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 5%;
      .banner-text {
        color: $themeColour2;
      }
    }
  }
}

.bank-header {
  &-search {
    margin: 30px 0;
    border-radius: 10px;
    padding: 23px 20px;
    .form-control {
      line-height: 23px;
      padding: 16px 40px;
      background: $themeBlueDark  url('../images/search.svg')  18px center no-repeat!important;
      color: $textOnBlue;
      border: none!important;
      &::placeholder {
        color: $darkTextOnBlue;
        opacity: 1; /* Firefox */
      }
    }
  }
  .tag-list {
    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin:0;
      li{
         margin-left: 30px;
       }
      a {
        color: $darkTextOnBlue;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.dropdown {
  ul {
    li {
      padding: 0 10px;
      a{
        margin-bottom: 5px;
        background: $boxesOnWhite;
        border-radius:10px;
        padding: 7px;
      }
      &:last-child {
        a {margin-bottom: 0;}
      }
      &:hover {
        a {
          background:$boxesOnWhiteHover;
        }
      }
    }
  }
}
