.sidebar {
  padding: 20px 10px;
  background: white;

  @media (min-width: 991px) {
    padding: 30px;
  }
  &-left {
    @media (min-width: 991px) {
      padding: 30px 2.8%;
    }
    display: flex;
    flex-direction: column;
    flex: 0 0 1;
    box-shadow: 1px 3px 3px 0px #0000001F;
    &.bg-blue {
      box-shadow: none;
    }
    .branding {
      display: flex;
      gap: 25px;
      &-heading {
        color: $bodyBg;
        line-height: 37px;
        position: relative;
        &:after {
          content:'';
          width: 30px;
          height: 36px;
          background: url('../images/verified.svg') center center no-repeat;
          display: block;
          position: absolute;
          right:15%;
          top:10%;
          transform: rotate(25deg);
        }
      }
      &-info {
        margin-top: 8px;
        font-size: 20px;
        line-height: 49px;
        color: $darkTextOnBlue;
      }
    }
  }

  &-right {
    @media (min-width: 991px) {
      width: 28.3%;
      min-width: 28.3%;
      box-shadow: -3px -1px 3px 0px #0000001F;
    }
    &.fit-content {
      align-self:flex-start;
      box-shadow: none;
    }
  }

  &-block {
    margin-bottom: 35px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .list-group .list-group-item {
      font-family: 'Futura PT Book'!important;
      font-weight: 400!important;
    }
    .list-group--contacts .list-group-item {
      &:hover {
        background:  $bodyBg;
      }
    }

    &.bordered {
      border-radius: 10px;
      border: 1px solid $lineOnWhite;
    }
    &-label {
      line-height: 25px;
      margin-bottom: 14px;
    }
    &-title {
      text-align: center;
      padding: 21px;
      font-family: 'Futura PT Medium';
      font-weight: 450;
      font-size: 28px;
      flex: 1;
      border-right: 1px solid $lineOnWhite;
      @media (min-width: 992px) {
        font-size: 35px;
        font-size: 1.8vw;
      }
      font-weight: 450;
      line-height: 45px;
    }
    &-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 21px;
      width: 30%;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
}

.page-sidebar-right {
  .main-content {
    @media (min-width: 991px) {
      width: 71.7%;
      min-width: 71.7%;
    }
  }
}
.page-home {
  .sidebar-left {
    @media (min-width: 991px) {
      padding: 45px 40px;
    }
  }
}

.bg-blue {
  .sidebar-block {
    &.bordered {
      border-color: $themeBlueDark;
    }
    .list-group .list-group-item {
      &:hover {
        background: $themeBlueDark;
        cursor: pointer;
      }
    }
    .list-group--contacts .list-group-item {
      &:hover {
        background:  $themeBlueDark;
      }
    }
  }
}
