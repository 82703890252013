.slider {
  position: relative;
  &:before {
      content: '';
      width: 100%;
      height: 105px;
      display: block;
      position: absolute;
      background: rgb(64,76,83);
      background: linear-gradient(180deg, rgba(64,76,83,1) 0%, rgba(64,76,83,0) 100%);
      top: -5px;
      left: 0;
      z-index: 2;
  }
  &:after {
      content: '';
      width: 100%;
      height: 105px;
      display: block;
      position: absolute;
      background: rgb(64,76,83);
      background: linear-gradient(0deg, rgba(64,76,83,1) 0%, rgba(64,76,83,0) 100%);
      bottom: -5px;
      left: 0;
      z-index: 2;
  }
}
.bg-white {
  .slider {
    &:before {
        background: rgb(64,76,83);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

    }
    &:after {

        background: rgb(64,76,83);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

    }
  }
}
