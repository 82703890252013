.form-control {
  outline: none!important;
  box-shadow: none!important;
  border-radius: 10px;
  line-height: 21px;
  &-blue {
    background-color: $themeBlue!important;
    border-color: $lineOnBlue!important;
    color:$textOnBlue!important;

    &::placeholder {
      color: $darkTextOnBlue;
      opacity: 1; /* Firefox */
    }
  }
}
input[type="search"].form-control-blue {
  padding: 14px 40px;
  background: $themeBlue  url('../images/search.svg')  18px center no-repeat!important;
}

.form-blue {
  label {
    color: $darkTextOnBlue;
  }
  .form-control {
      background-color: $themeBlue!important;
      border-color: $lineOnBlue!important;
      color:$textOnBlue!important;

      &::placeholder {
        color: $darkTextOnBlue;
        opacity: 1; /* Firefox */
      }
  }
}
.input-group {
  .input-group-text {
    border-radius: 10px 0 0 10px;
  }
  .form-control {
    border-radius: 0 10px 10px 0;
  }
}
.form-check {
  .form-check-input {
    box-shadow: none!important;
    &:focus {
      border-color:$linksAndBtns;
    }
  }
}
