.customCard {
  $name: &;
  border-radius: 10px;
  border: 1px solid $lineOnWhite;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  gap: 14px;
  flex-wrap: nowrap;
  flex-direction: column;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  &-img {
    min-width: 150px;
    width: 150px;
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    align-self: center;
    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }
  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .date {
      padding-left: 17px;
      font-size: 16px;
      background: url('../images/calendar.svg')  left center no-repeat;
      margin-bottom: 1px;
    }
    h5, .title {
      line-height: 23px;
      margin-bottom: 9px;
    }
  }
  &--right {
    @media (min-width: 767px) {
      flex-direction: row-reverse;
    }
  }
  &--sm {
    padding: 16px 20px;
    #{$name}-img {
      min-width: 117px;
      width: 117px;
      height: 117px;
    }
    #{$name}-text {
      font-size: 16px;
      h5, .title {
        font-size: 18px;
      }
    }
  }
  &--xs {
    padding: 16px 20px;
    #{$name}-img {
      min-width: 80px;
      width: 80px;
      height: 80px;
      @media (min-width: 767px) {
        align-self: flex-start;
      }
    }
    #{$name}-text {
      font-size: 16px;
      h5, .title {
        font-size: 18px;
      }
    }
  }
  &--partner {
    background: $bodyBg;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 23px;
    color: $textOnWhite;
    display: flex;
    flex-direction: column;
    gap:0;
    .border-bottom {
      border-color: white!important;
    }
    .site-url {
      a {
        color: $textOnWhite;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &:before {
        content:'';
        margin-right:8px;
        width: 30px;
        height: 30px;
        background: #fff url('../images/globe.svg')  center center no-repeat;
        border-radius: 10px;
        display: block;
      }
    }
    &:last-of-type{
      margin-bottom: 0;
    }
    .logo {
      width: 82px;
      height: 54px;
      background: white;
      border-radius: 10px;
      margin-right: 18px;
      display: flex;
      align-items: center;
      justify-content:center;
      img {
        max-height: 90%;
        width: auto;
      }
    }
    .title {
      font-family: 'Futura PT Medium';
      font-weight: 450;
      font-size: 18px;
    }
    .body {
      margin-top: 16px;
    }
  }
}

.company {
  .logo {
    width: 82px;
    height: 54px;
    border-radius: 10px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    img {
      max-height: 90%;
    }
  }
  .name {
    font-family: 'Futura PT Medium';
    font-weight: 450;
  }
  .btn {
    margin-left: auto;
  }
}

.card-article {
  background: #fff;
  box-shadow: 1px 3px 3px 0px #0000001F;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 6px 10px;
  @media (min-width: 767px) {
    max-width: calc(50% - 15px);
  }
  @media (min-width: 992px) {
    max-width: calc(25% - 23px);
  }
  &-tag {
    font-size: 14px;
    line-height: 18px;
    color:$themeBlue;
    margin-bottom: 6px;
  }
  &-image {
    border-radius: 10px;
    border: 1px solid $lineOnWhite;
    padding: 10px;
    height: 224px;
    margin-bottom: 22px;
    &-wrapper {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      max-height: 100%;
      height: 100%;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &-text {
    padding: 0 18px;
    p:last-of-type{
      margin: 0;
    }
    a {
      color: $linksAlt;
    }
  }
  &-link {
    margin-top: auto;
    padding: 25px 18px;
    color: $linksAlt;
    &:hover {
      text-decoration: none;
      color: mix(black, $linksAlt, 20);
    }
  }

}

.card-document {
  @media (min-width: 767px) {
    width: calc(50% - 15px);
  }

  $doc: &;
  background: white;
  box-shadow: 1px 3px 3px 0px #0000001F;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 0;
  #{$doc}-label {
     margin-top: 20px;
  }
  #{$doc}-title {
    flex:1;
    font-size: 22px;
    line-height: 28px;
    font-family: 'Futura PT Medium';
    font-weight: 450;
  }
  #{$doc}-img {
    overflow:hidden;
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
    #{$doc}-icon {
      position: absolute;
      z-index:2;
      right: 10px;
      top: 10px;
    }
  }
  #{$doc}-footer {
    background: $boxesOnWhite;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  &--cert {
    #{$doc}-header {
      background: $boxesOnWhite;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      align-items: center;
    }
    #{$doc}-img {
      background: white;
      border-radius: 10px;
      padding:13px 22px;
      width: 130px;
    }
  }
  &--achieve {
    #{$doc}-header {
      border-radius: 10px;
      padding: 10px;
      display: flex;
      align-items: center;
    }
    #{$doc}-img {
      border-radius: 10px 0 0 10px;
      width: 166px;
      height: 166px;
      border: 1px solid $lineOnWhite;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    #{$doc}-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0 10px 10px 0;
      border: 1px solid $lineOnWhite;
      height: 166px;
    }
  }
}
