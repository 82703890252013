.block {
  $name: &;
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &-header {
    border-radius: 10px 10px 0 0;
    background: $themeBlue;
    color: $bodyBg;
    font-family: 'Futura PT Medium';
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    padding: 13px 26px;
    display: flex;
    align-items: center;
    &-icon {
      border-radius: 10px;
      background: $boxesOnBlue;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right:8px;
      i {
        font-size: 12px;
      }
    }
    &--with-icon {
      padding: 13px 26px 11px;
    }
  }
  &-body {
    padding: 20px;
    background: white;
    box-shadow: 1px 3px 3px 0px #0000001F;
    border-radius: 10px;
  }
  &-header + .block-body {
    border-radius: 0 0 10px 10px;
  }
  &-image {
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0 25px;
    img{
      width: 100%;
      height: auto;
    }
  }
  &-footer {
    margin: 20px -20px 0;
    padding: 20px 20px 0;
    border-top: 1px solid $lineOnWhite;
    border-radius: 0 0 10px 10px;
    gap: 10px;
    flex-wrap:wrap;
  }
  &-body--featured {
    padding: 0;
    .banner-body {
      margin-bottom: 115px;
    }
  }
  .right {
    border-left: 1px solid $lineOnWhite;
    padding: 20px;
  }
  .left {
    padding: 20px;
  }
  .list-group {
    .list-group-item {
      background: $boxesOnWhite;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      font-family: 'Futura PT Medium';
      font-weight: 450;
      display: flex;
      align-items: center;
      &:hover {
        background: $boxesOnWhiteHover;
        cursor: pointer;
      }
      p {
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      > .icon {
        border-radius: 10px;
        background: white;
        color: $themeColour2;
        width: 30px;
        height: 30px;
        min-width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right:10px;
        &.bordered {
          border:1px solid $lineOnWhite;
        }
        i {
          font-size: 12px;
        }
      }
    }
    &.text-regular {
      .list-group-item {
        font-family: 'Futura PT Book';
        font-weight: 400;
      }
    }

    &--contacts {
      .list-group-item {
        background: transparent;
        border: none;
      }
      .list-group-item-wrap {
        border-bottom: 1px solid $lineOnWhite;
        padding: 6px;
        &:last-child {
          border: none
        }
      }
    }
    &.three-col {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px 20px;
      .list-group-item {
        margin: 0;
        width: calc(50% - 10px);
        @media (min-width: 767px) {
          width: calc(33.3% - 15px);
        }
      }
    }
    &--menu {
      font-size: 16px;
      line-height: 21px;
      a {
        color:$themeBlue!important;
        text-decoration: none!important;
      }
      .list-group-item {
        border: none!important;
        padding: 9.5px 14px;
        cursor: pointer;
        &:hover,
        &.active {
          background: $themeBlue;
          a {
            color:$bodyBg!important;
          }
        }
      }
    }
  }

  .two-cols {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .customCard {
      @media (min-width: 991px) {
        max-width: calc(50% - 20px);
      }
    }
  }
  .search--featured {
    background: $boxesOnWhite;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    .form-control {
      padding: 16px 120px 16px 40px;
      background: #fff url('../images/search.svg')  18px center no-repeat;
    }
    .btn {
      position: absolute;
      right: 27px;
      top: 50%;
      transform: translate(0,-50%);
    }
  }
  .search--results {
    margin-top: 20px;
    .list-group {
      margin-top: 17px;
      padding: 10px 0;
      border-top: 1px solid $lineOnWhite;
      border-bottom: 1px solid $lineOnWhite;
    }
  }
  .search--categories {
    margin-top: 30px;
    &:before{
      content: '';
      height: 1px;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-bottom: 20px;
      background: $lineOnWhite;
      display: block;
    }

    ul {
      border-top: 1px solid $lineOnWhite;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 20px;
      padding-top: 20px;
      padding-left: 16px;
      li {
        width: calc(50% - 15px);
        @media (min-width: 991px) {
          width: calc(14% - 15px);
        }
        font-size: 16px;
        line-height: 23px;
        &::marker {
          color: $linksAndBtns;
          margin-right: 0;
        }
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.bg-blue {
  .block {
    .list-group {
      .list-group-item {
        background: $themeBlueDark;
        border-color: $themeBlueDark;
        color:$bodyBg;
        a, a.link-blue {
          color:$linksAndBtns;
        }
        > .icon {
          background: $themeBlue;
          color: $bodyBg;
        }
        &.company {
          background: #4F5A61;
        }
      }
      &--contacts {
        .list-group-item {
          background: transparent;
          border: none;
        }
        .list-group-item-wrap {
          border-bottom: 1px solid $themeBlueDark;
        }
      }
    }
  }
}
