.page-article,
.page-legal {
  padding: 30px 0;
  .card-article {
    @media (min-width: 767px) {
      max-width: calc(50% - 7.5px);
    }
    @media (min-width: 992px) {
      max-width: calc(50% - 15px);
    }
  }
}
.article {
  &-body {
    padding: 20px 15px;
    @media (min-width: 992px) {
      padding: 35px 7.5% 50px;
    }
  }
  &-tag {
    font-size: 14px;
    line-height: 18px;
    color:$themeBlue;
    margin-bottom: 8px;
  }
  &-image {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    @media (min-width: 992px) {
      margin-bottom: 45px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &-title {
    margin-bottom: 23px;
    @media (max-width: 767px) {
      line-height: 30px;
      font-size: 25px;
    }
  }
  &-text {
    font-size: 20px;
    line-height: 28px;
    p, ol, ul {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul, ol {
      line-height: 35px;
    }
  }
}
