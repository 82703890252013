@font-face {
font-family: 'Futura PT Book';
font-style: normal;
font-weight: normal;
src: local('Futura PT Book'), url('font/FuturaPT-Book.woff') format('woff');
}


@font-face {
font-family: 'Futura PT Light';
font-style: normal;
font-weight: normal;
src: local('Futura PT Light'), url('font/FuturaPT-Light.woff') format('woff');
}


@font-face {
font-family: 'Futura PT Medium';
font-style: normal;
font-weight: normal;
src: local('Futura PT Medium'), url('font/FuturaPT-Medium.woff') format('woff');
}
