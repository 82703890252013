.btn {
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 17px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
  &-primary {
    background-color: $linksAndBtns;
    border-color:$linksAndBtns;
    color: white;
    &:hover {
      background-color: mix(black, $linksAndBtns, 20);
      border-color:mix(black, $linksAndBtns, 20);
      color: white;
    }
  }
  &-outline {
    color: $textOnWhite;
    border-color: $textOnWhite;
    &:hover {
      border-color: $linksAndBtns;
      background: $linksAndBtns;
      color: white;
    }
  }
  &-outline-onBlue {
    color: white;
    border-color: $lineOnBlue;
    &:hover {
      border-color: $linksAndBtns;
      background: $linksAndBtns;
      color: white;
    }
  }
  &-outline-onWhite {
    color: $themeBlue;
    border-color: $lineOnBlue;
    &:hover {
      border-color: $linksAndBtns;
      background: $linksAndBtns;
      color: white;
    }
  }
}
.buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
