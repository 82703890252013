footer {
  background: white;
  color: $textOnWhite;
  padding-top: 20px;
  margin-top: auto;
  h5 {
    line-height: 34px;
    margin-bottom: 15px;
  }
  ul.nav {
    li {
       margin-bottom: 5px;
       a {
           color: $lightTextOnWhite;
       }
     }
  }

  .form-blue {
    padding: 12px 20px;
    border-radius: 20px;
    color: $textOnBlue;
    .bg-owl,
    .lg-bg-owl {
      position: relative;
      &:before{
        content: '';
        width: 28px;
        height: 39px;
        background: url('../images/owl.svg') center center no-repeat;
        background-size: contain;
        position: absolute;
        top: -30px;
        z-index: 1;
        right: 10px;
      }
    }
      .lg-bg-owl:before {display: none;}
    @media (min-width: 1200px) {
      .bg-owl:before {display: none;}
      .lg-bg-owl:before {display: block;}
    }
    h5{
      font-size: 25px;
      font-weight: 450;
      line-height: 49px;
      margin-bottom: 0
    }
    .input-group-text {
      background-color: $boxesOnBlue;
    }
    .form-check {
      margin-bottom: 0;
    }
  }
  .footer-bottom {
    line-height: 49px;
    font-size: 16px;
    color: $textOnWhite;
    ul {
      li {
        a {
          color:$textOnWhite;
        }
        display: flex;
        align-items: center;
        &:after {
          content: '';
          width: 1px;
          height: 20px;
          background: $lineOnWhite;
          display: inline-block;
          margin: 0 20px;
        }
        &:last-child:after{display: none;}
      }
    }
  }
}
