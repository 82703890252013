// Basic variables

$themeBlue:             #404C53;
$themeColour2:          #1E1656;
$themeBlueDark:         #364045;

$bodyBg:                #F3F3F5;
$linksAndBtns:          #59B54D;
$linksAlt:              #3571C4;

$textOnBlue:            #E1E2E5;
$darkTextOnBlue:        #A2A8AB;
$lineOnBlue:            #646D73;
$boxesOnBlue:           #4E5960;

$textOnWhite:           #273138;
$lightTextOnWhite:      #778188;
$additionalTextOnWhite: #1E1C2A;
$lineOnWhite:           #E8E9EA;
$boxesOnWhite:          #EBEDED;
$boxesOnWhiteHover:     #D9D9D9;



//Bootstrap override
// $primary:       $linksAndBtns !default;
$breadcrumb-divider: quote(">");
$breadcrumb-divider-color:  $additionalTextOnWhite;
$breadcrumb-active-color:   $additionalTextOnWhite;

$input-group-addon-color:               $darkTextOnBlue;
$input-group-addon-bg:                  $themeBlue;
$input-group-addon-border-color:        $lineOnBlue;

$form-check-input-bg:                     $themeBlue;
$form-check-input-border:                 1px solid $lineOnBlue;
$form-check-input-checked-bg-color:       $linksAndBtns;
