.header.navbar-expand-lg {
  background-color: $themeBlue;
  padding: 9px 0;
  .logo {
    img {
      max-width: 183px;
    }
  }
  .divider {
    height: 48px;
    width:1px;
    background: $lineOnBlue;
    margin: 0 30px;
    display: inline-block;
  }

    ul.navbar-nav {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-evenly;
      > li {
        display: block;
        > span {
          color: $darkTextOnBlue;
          padding: 4px 0;
          margin-left: 15px;
          display: block;
        }
        > a {
          display: block;
          color: $textOnBlue;
          padding: 4px 9px;
          margin: 0 7px;
          text-decoration: none;
          border-radius: 10px;
          transition: .25s linear all;
          white-space: nowrap;
          &:hover,
          &.active {
            background: $boxesOnBlue;
          }

        }
      }
    }
}
.header-bottom {
  background: white;
  padding: 11px 0;
  position: relative;
  color: $lightTextOnWhite;
  box-shadow: 1px 3px 3px 0px #0000001F;
  z-index:0;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    left: 3%;
    width: 15px;
    height: 13px;
    background: url('../images/tail.svg')  center center no-repeat;
    z-index:1;
  }
  .breadcrumb {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 18px;
    .active.lowest {
      opacity: 0.6;
    }
    a {
      text-decoration: none;
      color: $additionalTextOnWhite;
    }
    .hp {
      font-size: 16px;
      line-height: 20px;
      color: $lightTextOnWhite!important;
    }
  }
}
